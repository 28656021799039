import BasePortfolioEntry from "./BasePortfolioEntry";

import adepth_img from "../../Images/Depth/adepthLR.jpg"
import adepth_res_img from "../../Images/Depth/adepth_res.png"


class ADepthLR extends BasePortfolioEntry {
    static mypath='adepthlr'
    static page_title = "Adversarial Depth Estimation based on Left-Right Consistency";
    static page_image = adepth_img;
    static page_briefs = "In this work, we build upon previous work on using Left-Right consistency for depth estimation. The conventional approach in such methods is using a series of hand-designed loss metrics to measure the consistency between the left-eye and right-image, which limits the capabilities of such models. In this work, we borrow from Generative Adversarial Networks (GAN) and propose a method for LR-Consistency that learns the consistency/loss function with goal of discriminating the inconsistent Images.";

    static page_keywords = ["Deep Learning", "Computer Vision", "Depth Estimation", "Left-Right Consistency", "Generative Adversarial Networks"]

    constructor(props) {
        super(props);
        this.research_title = ADepthLR.page_title;
        this.research_highlights = [
            "Depth estimation in urban scenes using Generative-Adversarial Networks (GAN)",
            "Full Presentation <a href='https://proska.github.io/ADepthLR/#/overview'>here</a>"
        ];
        this.research_details = [
            "Depth estimation or Depth extraction refers to the set of methods and algorithms specifically aimed to obtain an\n" +
            "estimation of the spatial structure of a scene based on the Images or videos of it. In other terms, the goal is to\n" +
            "obtain a measure of the distance of, ideally, each point on these images1\n" +
            ". this notion on predicted depth, is an\n" +
            "essential component in the field of multi-view stereo2 or understanding the 3D geometry of the scene, which is\n" +
            "crucial in many applications3\n" +
            ", e.g. unmanned vehicle, robotics, and human body pose estimation4\n" +
            ".\n" +
            "Historically, researchers have looked at depth estimation problem in supervised learning setup. Models in this\n" +
            "setup attempt to directly predict the depth of each pixel in an image using models that have been trained off-line on\n" +
            "large collections of labeled datasets(ground truth depth data). Although these methods seem simple, there is no\n" +
            "universally acceptable method to generate this type of dataset, for two reasons. First, equipments necessary for\n" +
            "measuring depth data (e.g. laser sensors) are expensive and considered to be extremely noisy which introduced\n" +
            "additional challenge for current machine learning techniques. Second, even a in perfect world with noise-free\n" +
            "affordable equipments, discarding available bank of unlabeled Images and generating large datasets of labeled\n" +
            "data will restrict our model to scenes where labeled data is available. Consequently we need to generate sufficiently\n" +
            "representative and large datasets that requires large time and money investments.\n" +
            "In order to train a model while utilizing the currently available unlabeled (no depth dimension) image datasets,\n" +
            "inspired by humans visual system, researchers have proposed monocular depth estimation methods that exploit\n" +
            "cues such as perspective, lighting, etc. One state-of-art method in this field is based on binocular image generation\n" +
            "using convolutional neural networks4\n" +
            ". In this project, we first aim at recreating the reported results in4\n" +
            ". Additionally\n" +
            "inspired by works in the field of adversarial learning5\n" +
            ", to learn consistency features used in training instead of\n" +
            "hard coding them, we plan to build on the adversarial idea to improve performance and training time of the depth\n" +
            "estimation model."
        ]
        this.images = [
            adepth_img, adepth_res_img
        ]
        this.citations = [

        ]
    }

}

export default ADepthLR;