import BasePortfolioEntry from "./BasePortfolioEntry";


import blokus_base_img from "../../Images/Blokus/blokus.jpg"
import blokus_3_img from "../../Images/Blokus/blokus_3.png"
import blokus_4_img from "../../Images/Blokus/blokus_4.png"
import blokus_9_img from "../../Images/Blokus/blokus_9.png"


class Blokus extends BasePortfolioEntry {
    static mypath = 'blokus'
    static page_title = "Blokus-Duo AI Agent based on Monte-Carlo Tree Search (MCTS)";
    static page_image = blokus_base_img;
    static page_briefs = "This research focuses on our proposed hardware architecture on a highly scalable, shared-memory, MonteCarlo Tree Search (MCTS) based Blokus-Duo solver. " +
        "Our design is inspired from parallel MCTS algorithms and is potentially capable of obtaining maximum possible parallelism from MCTS" +
        "algorithm. We also combine MCTS with set of pruning heuristics to increase both the memory and Logic Element (LE) utilizations.";

    static page_keywords = ["Multi-Armed Bandit", "Monte-Carlo Tree Search", "Massively Parallel Architecture", "Combinatorial", "Optimization", 'AI']

    constructor(props) {
        super(props);
        this.research_title = Blokus.page_title;
        this.research_highlights = [
            "HW/SW co-design of Highly parallel Blokus-Duo Solver based on Monte Carlo Tree Search (MCTS) Engine on Terasic DE2-115 FPGA board. ",
        ];
        this.research_details = [
            "This research focuses on our proposed hardware" +
            "architecture on a highly scalable, shared-memory, MonteCarlo Tree Search (MCTS) based Blokus-Duo solver. In the" +
            "proposed architecture each MCTS solver module contains a" +
            "centralized MCTS controller which can also be implemented" +
            "using soft-cores with a true dual-port access to a shared" +
            "memory called main memory, and multitude number of MCTS" +
            "engines each containing several simulation cores." +
            "Consequently, this highly flexible architecture guaranties the" +
            "optimized performance of the solver regardless of the actual" +
            "FPGA platform used. Our design has been inspired from" +
            "parallel MCTS algorithms and is potentially capable of" +
            "obtaining maximum possible parallelism from MCTS" +
            "algorithm. On the other hand, in our design we combine" +
            "MCTS with pruning heuristics to increase both the memory" +
            "and LE utilizations. The results show that our architecture can" +
            "run up to 50MHz on DE2-115 platform, where each" +
            "Simulation core requires 11K LEs and MCTS controller" +
            "requires 10K LEs. ",
            "For implementation, I supervised a group of 9 undergraduate students from ECE of UT through their " +
            "training (both in algorithm and hardware design) for 1 month and finally through their hardware " +
            "implementation of the MCTS based Blokus-duo engine on FPGA to participate in the ICFPT2014 design " +
            "competition which was held on Dec 2014 in China."
        ]
        this.images = [
            blokus_base_img, blokus_3_img, blokus_4_img, blokus_9_img,
        ]
        this.citations = [
            "Qasemi E, Samadi A, Shadmehr MH, Azizian B, Mozaffari S, Shirian A, Alizadeh B. Highly scalable, shared-memory, Monte-Carlo tree search based Blokus Duo Solver on FPGA. In2014 International Conference on Field-Programmable Technology (FPT) 2014 Dec 10 (pp. 370-373). IEEE.",
        ]
    }

}

export default Blokus;