import React, { Component } from 'react';
import {Route} from 'react-router-dom';

import './styles/bootstrap-4.1.2/bootstrap.min.css'
import './styles/main_styles.css';
import './styles/responsive.css';

import MyTabsList from './Tabs/TabsList'

class App extends Component {
    render() {
        return (
            <div>
                <Route path="/" component={MyTabsList[0]} exact/>
                <Route path="/MyCalendar" component={() => {
                    window.location.href = 'https://calendar.google.com/calendar/embed?src=qasemi.ehs%40gmail.com&ctz=America%2FLos_Angeles';
                    return null;
                }}/>
                {
                    MyTabsList.map(function (comp) {
                        let path = comp.mypath.toLowerCase()
                        return <Route exact path={`/${path}`} component={comp} key={path}/>
                    })
                }
                {
                    MyTabsList.map((comp) => {
                        if (comp.subpages.length ===0){
                            return []
                        }
                        return comp.subpages.map(sub_comp => {
                            let sub_path = `/${comp.mypath.toLowerCase()}/${sub_comp.mypath}`
                            return <Route exact
                                          path={sub_path}
                                          component={sub_comp}
                                          key={sub_path}/>
                        })
                    })
                }

                {/*{this.getSuitableStyle()}*/}
            </div>
        );
    }

    // getSuitableStyle() {
    //     const queries = this.getMediaQueries();
    //     let styles = STYLES_LUT
    //
    //     let out = (
    //         <Media queries={queries}>
    //             {matches => {
    //
    //                 let ps = Object.keys(queries).map((k, i) => (
    //
    //                     matches[k]? styles[k]: ''
    //                 ))
    //                 // let ps = styles[largest]
    //                 return (
    //                     <style>
    //                         {ps}
    //                     </style>
    //                 )
    //
    //             }}
    //         </Media>
    //     )
    //
    //     return out
    // }
    //
    // getMediaQueries() {
    //     const queries = {
    //         w1600: 'only screen and (max-width: 1600px)',
    //         w1440: 'only screen and (max-width: 1440px)',
    //         w1280: 'only screen and (max-width: 1280px)',
    //         w1199: 'only screen and (max-width: 1199px)',
    //         w1024: 'only screen and (max-width: 1024px)',
    //         w991: 'only screen and (max-width: 991px)',
    //         w959: 'only screen and (max-width: 959px)',
    //         w880: 'only screen and (max-width: 880px)',
    //         w768: 'only screen and (max-width: 768px)',
    //         w767: 'only screen and (max-width: 767px)',
    //         w539: 'only screen and (max-width: 539px)',
    //         w479: 'only screen and (max-width: 479px)',
    //         w400: 'only screen and (max-width: 400px)',
    //     }
    //     return queries;
    // }

}

export default App;
