import BasePortfolioEntry from "./BasePortfolioEntry";


import aes_base_img from "../../Images/AES/aes.jpg"


class AES extends BasePortfolioEntry {
    static mypath = 'aes'
    static page_title = "Maestro: A High Performance AES Encryption / Decryption System";
    static page_image = aes_base_img;
    static page_briefs = "This research focuses on our proposed hardware architecture on a highly scalable, shared-memory, MonteCarlo Tree Search (MCTS) based Blokus-Duo solver. " +
    "Our design is inspired from parallel MCTS algorithms and is potentially capable of obtaining maximum possible parallelism from MCTS" +
    "algorithm. We also combine MCTS with set of pruning heuristics to increase both the memory and Logic Element (LE) utilization.";

    static page_keywords = ["Advanced Encryption Standard", "AES", "Security", "Encryption", "Decryption", "FPGA"]

    constructor(props) {
        super(props);
        this.research_title = AES.page_title;
        this.research_highlights = [
            "Awarded 1’st place in National Digital System Design Competition, HW/SW Co-design League, Iran",
            "Propose a high-throughput AES architecture for both encryption and decryption",
            "Achieve 12.8 Gbps end-to-end throughput on mid-tier DE2-115 FPGA",
        ];
        this.research_details = [
            "High throughput AES encryption/decryption is a" +
            "necessity for many of modern embedded systems. This article" +
            "presents a high performance yet cost efficient AES system." +
            "Maestro can be used in a wide range of embedded applications" +
            "with various requirements and limitations. Maestro is about one" +
            "million times faster than the pure software implementation. The" +
            "Maestro architecture is composed of two major components; the" +
            "soft processor aimed at system initialization and control, and the" +
            "hardware AES engine for high performance AES" +
            "encryption/decryption. A ten stage implicit pipelined" +
            "architecture is considered for the AES engine. Two novel" +
            "techniques are proposed in design of AES engine which enable it" +
            "to reach a throughput of 12.8 Gbps. First, tightly coupled" +
            "encryption and round key generation units in encryption unit," +
            "and second, ahead of time round key generation in decryption" +
            "unit. Altera DE2-115 development and educational FPGA board" +
            "is used as the platform for Maestro. In the proposed architecture" +
            "the DMA modules act as interfaces between data sources and" +
            "data sinks by loading the input data into AES engine and taking" +
            "encrypted and generated test data to target memories. ",
        ]
        this.images = [
            aes_base_img
        ]
        this.citations = [
            "Biglari M, Qasemi E, Pourmohseni B. Maestro: A high performance AES encryption/decryption system. InThe 17th CSI International Symposium on Computer Architecture & Digital Systems (CADS 2013) 2013 Oct 30 (pp. 145-148). IEEE.",
        ]
    }

}

export default AES;