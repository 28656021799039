import BasePortfolioEntry from "./BasePortfolioEntry";

import brain_img from "../../Images/Asd/brain_imaging.jpg"
import human_img from "../../Images/Asd/Vitruvianischer_Mann.png"
import asd_arch_img from "../../Images/Asd/ASD.jpg"

class ASD extends BasePortfolioEntry {
    static mypath='asd'
    static page_title = "Detecting Autism Spectrum Disorder (ASD): Learning Empirical Space for Spatio-temporal Data Analysis";
    static page_image = human_img;
    static page_briefs = "Here, we investigated spatio-temporal data analysis methods in motor-based early ASD (autism spectrum disorder) detection application. The challenge here is processing the stream multi-modal (Motion, fMRI, EEG, and DTI) information with the goal of detecting early signs of ASD in the human subject. We propose two solutions, with the ability to achieve 80% accuracy. Additionally, we have extracted a set of entropy-based features that can help explain the decision-making process of the network and improve confidence in the results.";

    static page_keywords = ["Clustering", "Autism Spectrum Disorder (ASD)", "Entropy", "Spaciotemporal", "Kinect"]

    constructor(props) {
        super(props);
        this.research_title = ASD.page_title;
        this.research_highlights = [
            "Novel clustering and data visualization methods for discovery of ASD personalized therapy.",
            "Propose an architecture based on Deep Recurrent Neural Network for motor-based early ASD (autism spectrum disorder) detection.",
            "Development of physics-based entropy methods in ASD feature discovery, and pattern classification.",
        ];
        this.research_details = [
            "This is a study on autism spectrum disorder (ASD). Our experiments use a collection of " +
            "anonymized clinical time series gathered at Waisman Center of UW-Madison as part of an " +
            "IRB-approved study. the goal here is to analyze and process massive raw data gathered at " +
            "Motor Brain and Development Lab. from volunteers. Each data file corresponds to one human " +
            "subject going through specifically designed procedures or poses. A row in each file records " +
            "two types of sensory data at a specific time (Timestamp). The first type contains balance " +
            "board data which records the Subject’s center of gravity with respect to the center of the " +
            "board. The second type of sensory data is Kinect camera data of subject’s position. This data " +
            "is generated by processing Kinect’s generated video to indicate specific points on subject's body " +
            "and track them. Overall the image processing method tracks the cartesian coordinates of 20 points " +
            "on the subject’s body in XYZ coordinates.",
            "As a data analyst, our job was to first clean the dataset from various noises usually " +
            "associated with this kind of data. At this point, we have proposed two methods to study " +
            "this data: First methods is based on physical aspects of the\n" +
            "problem and focuses Entropy as a main feature of the motion data. The second method\n" +
            "is based on our specialized Deep recurrent Neural Network architecture that builds\n" +
            "on a vast amount of work in this context.",
        ]
        this.images = [asd_arch_img, brain_img]
    }

}

export default ASD;