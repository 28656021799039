import React, {Component} from 'react';
import '../styles/bootstrap-4.1.2/bootstrap.min.css'
import '../styles/main_styles.css';
import '../styles/responsive.css';
import {Link} from 'react-router-dom';


class NavList extends Component {
    header_items;
    page_name;

    render() {
        return (
            <div className={"main_nav d-flex flex-row align-items-end justify-content-start"}>
                <ul className={"d-flex flex-row align-items-center justify-content-start"}>
                    {
                        this.props.header_items.map((comp) => {
                            let path = comp.mypath.toLowerCase()
                            let mypath = this.props.page_name.toLowerCase()
                            let active = (mypath === path)?'active':'';
                            return <li className={active} key={path}>
                                <Link to={'/' + path}>{comp.mypath}</Link>
                            </li>
                        })
                    }
                </ul>
            </div>

        );
    }
}

export default NavList;