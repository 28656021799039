import React from 'react';
import { render } from 'react-snapshot';
import App from './App';
import { BrowserRouter as Router}  from 'react-router-dom'


// ReactDOM.render(
render(
    <Router><App /></Router>,
    document.getElementById('root')
);
