import React from 'react';

import BaseTab from "../BaseTab";

import '../../styles/responsive.css';
import '../../styles/main_styles.css';

class BasePortfolioEntry extends BaseTab {
    static page_title = "TODO";
    static page_image = "";
    static page_briefs = "";
    static page_keywords = []

    static external_link = undefined;

    static mypath='BasePortfolioEntry'

    constructor(props) {
        super(props);
        this.research_title = BasePortfolioEntry.page_title;
        this.research_highlights = [];
        this.research_details = []
        this.images = []

        this.citations = []
    }


    getContent() {
        return(
            <div className="main_content">
                <div className="main_content_scroll mCustomScrollbar" data-mcs-theme="minimal-dark">
                    <div className="about_content">
                        <div className="research_title">{this.research_title}</div>
                        {this.getHighlights()}
                        {this.getPublications()}
                        {this.getDetails()}
                        {this.getAlbum()}
                    </div>
                </div>
            </div>
        )
    }

    getAlbum() {
        if (!this.images.length)
            return;

        return (
            <div>
                <div className="research_section_title">Album:</div>
                <br/>
                {
                    this.images.map(m => (
                        <div key={m}>
                            <img src={m} className="img-fluid" alt="Responsive" key={m}
                                 style={{marginBottom:'30px'}}/>
                        </div>
                    ))
                }
            </div>
        );
    }

    getHighlights() {
        if (!this.research_highlights.length)
            return;

        return (
            <div>
                <div className="research_section_title">Highlights:</div>
                <div className="my_research_text">
                    <ul>
                        {this.research_highlights.map((t) => (
                            <li style={{color: "black"}} key={t} dangerouslySetInnerHTML={{ __html: "-> "+t }}/>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }

    getDetails() {
        if (!this.research_details.length)
            return;

        return (
            <div>
                <div className="research_section_title">Details:</div>
                <div className="my_research_text">
                    {this.research_details.map((t) => (
                        <p style={{color: "black"}} key={t}> {t} <br/></p>
                    ))}
                </div>
                <hr/>
            </div>
        );
    }

    getPublications() {
        if (!this.citations.length)
            return;

        return (
            <div>
                <div className="research_section_title">Publications:</div>
                <div className="my_research_text">
                    <ol>
                        {this.citations.map(bbtex => (<li key={bbtex.toString()}>{bbtex}</li>))}
                    </ol>
                </div>
                <hr/>
            </div>
        );
    }
}

export default BasePortfolioEntry;