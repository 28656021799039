import React, {Component} from 'react';
import '../styles/bootstrap-4.1.2/bootstrap.min.css'
import '../styles/responsive.css';
import '../styles/main_styles.css';
import {Link} from 'react-router-dom';

class NavMenu extends Component {
    header_items;
    page_name;

    constructor(props) {
        super(props);
        this.state = {
            menuActive: false
        }
    }
    render() {
        let active = this.state.menuActive ? ' active': '';
        return (
            <div className={"menu" + active}>
                <div className={"menu_content d-flex flex-row align-items-start justify-content-end"}>
                    <div className={"hamburger ml-auto"}>menu</div>
                    <div className={"menu_nav text-right"}>
                        <ul>
                            {
                                this.props.header_items.map((comp) => {
                                    let path = comp.mypath.toLowerCase()
                                    return <li key={path}>
                                        <Link to={'/' + path}>{comp.mypath}</Link>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }



}

export default NavMenu;