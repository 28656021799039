import React from 'react';
import '../styles/bootstrap-4.1.2/bootstrap.min.css'
import '../styles/main_styles.css';
import '../styles/responsive.css';
import MyProgBar from "../components/MyProgBar";
import BaseTab from "./BaseTab";

class Skills extends BaseTab {

    static subpages = []
    static mypath='Skills'

    lineBreak = <hr style={{
        "height": "2px",
        "border": "none",
        "color": "#333",
        "backgroundColor":"#333"
    }}/>;

    createSkillBlock(name, item = []){
        return (
            <div key={name}>
                <div className="main_subtitle">{name}</div>
                <br/>
                <div className="container-fluid">
                    <div className="row">
                        {
                            item.map(function (skill_num) {
                                let skill = skill_num[0]
                                let num = skill_num[1]


                                return (
                                    <div className="col-xl-4 col-lg-6 pb_col" key={skill_num} id={skill}>
                                        <MyProgBar title={skill} number={num}/>
                                    </div>
                                )

                            })
                        }
                    </div>
                </div>
                {this.lineBreak}
            </div>
        )
    }

    getContent() {
        return (
            <div className="main_content">
                <div className="main_title_container d-flex flex-column align-items-start justify-content-end">
                    <div className="main_subtitle">What I am good at</div>
                    <div className="main_title">My Skills</div>
                </div>

                <div className="main_content_scroll mCustomScrollbar" data-mcs-theme="minimal-dark">
                    <div className="skills">
                        <div className="progress_bars">
                            {this.lineBreak}
                            {
                                // let this =this
                                this.all_skills.map((items) => {
                                    let name = items[0]
                                    let subitems = items[1]
                                    return this.createSkillBlock(name, subitems)
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    constructor(props) {
        super(props);
        this.all_skills = [
            ["Programming Languages", [
                ['Python', 95],
                ['Scala', 85],
                ['C/C++', 80],
                ['Matlab', 95],
                ['Julia', 85],
                ['Java', 70],
                ['Cuda', 70],
                ['R', 60],
                ['OpenCL', 60],
                ['Assembly', 95],
                ['Octave', 85],
                ['Lua', 40],
            ]],
            ["Machine Learning & Deep Learning Libraries", [
                ['Tensorflow', 95],
                ['Pytorch', 95],
                ['Keras', 90],
                ['Nltk', 80],
                ['OpenGL', 75],
                ['scikit-learn', 99],
            ]],
            ["Big Data", [
                ['Spark(ML, X, SQL)', 85],
                ['Apache(Hadoop, Ray)', 75],
                ['SQL', 95],
                ['Redis', 85],
                ['SPQRQL', 85],
            ]],
            ["Optimization", [
                ['Gorubi', 75],
                ['Cplex', 75],
                ['JuMP', 90],
            ]],
            ["Web Dev.", [
                ['React', 80],
                ['HTML', 95],
                ['CSS', 80],
                ['JavaScript', 80],
                ['Django', 80],
                ['Flusk', 70],
                ['Markdown', 90],
            ]],
            ["Others", [
                ['networkx', 95],
                ['Pandas', 95],
                ['Bokeh', 75],
                ['matplotlib', 95],
                ['Scipy', 95],
                ['Jupyter', 95],
            ]],
        ]
    }

}

export default Skills;
