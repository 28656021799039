import BasePortfolioEntry from "./BasePortfolioEntry";

import baroque_img from "../../Images/Baroque/baroque_sample.jpg"
import baroque_pipe_img from "../../Images/Baroque/Painting.png"
import baroque_prob_img from "../../Images/Baroque/Painting_Problem.png"
import baroque_svd_img from "../../Images/Baroque/Painting_SVD.png"


class DeepArt extends BasePortfolioEntry {
    static mypath='deep_art'
    static page_title = "Deep Leaning in Art History";
    static page_image = baroque_img;
    static page_briefs = "In this work, we studied deep learning applications in elucidating the relationships among painting. The painting dataset consists of three periods of Pre-Baroque, Baroque, and post-baroque.";

    static page_keywords = ["Deep Learning", "Data Science", "Computer Vision", "Spaciotemporal", "Art History"]

    constructor(props) {
        super(props);
        this.research_title = "Deep Leaning in Art History";
        this.research_highlights = [
            "Study deep learning applications in elucidating the relationships among painting in the three periods",
            "Big data local-to-global methods in analysis and prediction of dynamics using multiple modalities",
        ];
        this.research_details = [
            "Baroque is a unique period for emergence of\n" +
            "creative novelties in artistic ideas and technique improvement.\n" +
            "Baroque art shows the influence of pre-Baroque period’s\n" +
            "humanism perspective, and it exerted an extraordinary\n" +
            "influence on art following it. In the following, computational\n" +
            "intelligence and deep learning are applied to elucidate the\n" +
            "relationships among painting in the three periods. We outline\n" +
            "the methodology and reports a successful preliminary study\n" +
            "from limited data, which explored the concepts/techniques that\n" +
            "have remained, or disappeared during the historical changes,\n" +
            "and elucidated the connection among the Pre-Baroque, Baroque\n" +
            "Period and Post-Baroque Periods."
        ]
        this.images = [baroque_pipe_img, baroque_prob_img, baroque_svd_img]
        this.citations = [
            "Yuchen S, Qasemi E, Ardalan A, Gao H, Assadi AH. Deep Learning Art History from Data: Baroque Intellectual Influence on the Romantic Era Painting. In2017 International Conference on Computational Science and Computational Intelligence (CSCI) 2017 Dec 14 (pp. 391-393). IEEE."
        ]
    }

}

export default DeepArt;