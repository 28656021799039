import React, {Component} from 'react';
import '../styles/bootstrap-4.1.2/bootstrap.min.css'
import '../styles/responsive.css';
import '../styles/main_styles.css';
import NavMenu from "./NavMenu";
import NavList from "./NavList";

class HeaderTabs extends Component {
    all_tabs;
    page_name;

    constructor(props) {
        super(props);
        this.state = {
            isScrolled : false,
            menuActive: false,
        };
        this.NavMenuElement = React.createRef();
    }
    render() {
        let scrolled = this.state.isScrolled ? ' scrolled':'';

        return (
            <header className={"header"+scrolled} id={"main_header"}>
                <div className={"header_content d-flex flex-row align-items-center justify-content-start"}>
                    <div className={"logo"}>
                        Ehsan Qasemi
                    </div>
                    <NavList header_items={this.props.all_tabs}
                             page_name={this.props.page_name}/>

                    <NavMenu ref={this.NavMenuElement}
                             header_items={this.props.all_tabs}
                             page_name={this.props.page_name}/>
                </div>
            </header>
        );
    }

    openMenu()
    {
        // console.log("OpenMenu")
        this.NavMenuElement.current.setState({menuActive: true})
        this.setState({menuActive: true})
    }

    closeMenu()
    {
        // console.log("CloseMenu")
        this.NavMenuElement.current.setState({menuActive: false})
        this.setState({menuActive: false})
    }

    initMenu()
    {
        let hamb = document.getElementsByClassName('hamburger').item(0)
        let menu = document.getElementsByClassName('menu').item(0)
        // console.log("Init Menu")
        if(hamb && menu)
        {
            // console.log("Init Menu > inside " + this.state.menuActive)
            hamb.addEventListener('click', () => {
                if(!this.state.menuActive){
                    this.openMenu()
                } else {
                    this.closeMenu()
                }
            })
        }
    }

    setHeader()
    {
        let dist = window.scrollY
        if(dist > 91)
        {
            // console.log("setHeader: True "+dist)
            this.setState({isScrolled: true})
        }
        else
        {
            // console.log("setHeader: False "+dist)
            this.setState({isScrolled: false})
        }
    }

    componentDidMount() {
        this.setHeader()
        // let header = document.getElementById('main_header')

        window.addEventListener('resize', () => {
            // console.log("window resized")
            this.setHeader()
            if(window.innerWidth > 1280)
            {
                if(this.state.menuActive)
                {
                    this.closeMenu();
                }
            }
        })

        document.addEventListener('scroll', () => {
            // console.log("document scrolled")
            this.setHeader();
        })

        this.initMenu()
    }

}

export default HeaderTabs;