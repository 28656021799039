import BasePortfolioEntry from "./BasePortfolioEntry";

import pinks_logo from "../../Images/PInKS/PInKS_Overview.jpg";
import pinks_inference from "../../Images/PInKS/Precondtioned_Inference.jpg";
import pinks_extracted from "../../Images/PInKS/extracteds.png";
import pinks_results from "../../Images/PInKS/results.png";
import pinks_examples from "../../Images/PInKS/examples.png";



class PInKS extends BasePortfolioEntry {
    static mypath='pinks'
    static page_title = "PInKS: Preconditioned Commonsense Inference with Weak Supervision";
    static page_image = pinks_logo;
    static page_briefs = "Propose an improved model for reasoning with preconditions through minimum supervision. ";

    static page_keywords = ["Weak Supervision", "PAC-Bayesian", "Knowledge Graph", "CommonSense", "NLP"]

    constructor(props) {
        super(props);
        this.research_title = PInKS.page_title;
        this.research_highlights = [
            "Propose an improved model for reasoning with preconditions through minimum supervision. ",
        ];
        this.research_details = [
            "Reasoning with preconditions such as “glass can be used for drinking water unless the glass is shattered” remains an open-problem for language models. The main challenge lies in the scarcity of preconditions data and model’s lack of support for such reasoning. We present PInKS , Preconditioned Commonsense Inference with WeaK Supervision, an improved model for reasoning with preconditions through minimum supervision. We show, both empirically and theoretically, that PInKS improves the results across the benchmarks on reasoning with the preconditions of commonsense knowledge (up to 0.4 Macro-F1 scores). We further investigate the robustness of our method through PAC-Bayesian informativeness analysis, recall measures and ablation study.",
        ]
        this.images = [
            pinks_inference, pinks_extracted, pinks_examples, pinks_results
        ]
        this.citations = [
            "Qasemi E, Khanna P, Ning Q, Chen M. \"PInKS: Preconditioned Inference with Weak Supervision\", AACL 2022",
        ]
    }

}

export default PInKS;