import BasePortfolioEntry from "./BasePortfolioEntry";

import table_und_img from "../../Images/TableUnd/tableU.jpg"
import tbu_img from "../../Images/TableUnd/tbu.png";


class TableUnderstanding extends BasePortfolioEntry {
    static mypath='tableund'
    static page_title = "Tabular Data to Knowledge Graph Matching";
    static page_image = table_und_img;
    static page_briefs = "Aim of this research is to understand tabular data based on actual contents and the meta-data of table (e.g. styling, headers) and map the content to entities and relations from knowledge graphs such as dbpedia and wikidata.";

    static page_keywords = ["Knowledge Graph Embedding", "NLP", "Wikidata", "DBPedia", "Sentence Encoder", "Entity Matching"]

    constructor(props) {
        super(props);
        this.research_title = TableUnderstanding.page_title;
        this.research_highlights = [
            "Aim of this research is to understand tabular data based on actual contents and the meta-data of table (e.g. styling, headers) and map the content to entities and relations from knowledge graphs such as dbpedia and wikidata",
            "This work was prepared for <a href=\"https://www.aicrowd.com/organizers/tabular-data-to-knowledge-graph-matching\">ISWC (2019) table understanding challenges",
        ];
        this.research_details = [
        ]
        this.images = [
            table_und_img, tbu_img
        ]
        this.citations = [
            "Thawani A, Hu M, Hu E, Zafar H, Divvala NT, Singh A, Qasemi E, Szekely PA, Pujara J. Entity Linking to Knowledge Graphs to Infer Column Types and Properties. InSemTab@ ISWC 2019 (pp. 25-32)."
        ]
    }

}

export default TableUnderstanding;