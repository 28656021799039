import BasePortfolioEntry from "./BasePortfolioEntry";

import dsbox_poster_img from "../../Images/DSBox/DSBox_GSSISI_poster_H.jpg"
import dsbox_1_img from "../../Images/DSBox/dsbox_1.png"
import dsbox_2_img from "../../Images/DSBox/dsbox_2.png"
import dsbox_3_img from "../../Images/DSBox/dsbox_3.png"
import dsbox_4_img from "../../Images/DSBox/dsbox_4.png"
import dsbox_5_img from "../../Images/DSBox/dsbox_5.png"
import dsbox_6_img from "../../Images/DSBox/dsbox_6.png"
import dsbox_7_img from "../../Images/DSBox/dsbox_7.png"

import dsbox_tab_1_img from "../../Images/DSBox/dsbox_tab_1.png"
import dsbox_tab_3_img from "../../Images/DSBox/dsbox_tab_3.png"
import dsbox_tab_4_img from "../../Images/DSBox/dsbox_tab_4.png"

import dsbox_img from "../../Images/DSBox/dsbox.png"


class DSBox extends BasePortfolioEntry {
    static mypath='dsbox'
    static page_title = "DSBox: Data Scientist In A Box";
    static page_image = dsbox_img;
    static page_briefs = "End-to-End Automated Machine Learning (E2E-AutoML) seeks to automate the " +
        "machine learning pipeline generation process. In this work we present DSBox, an expandable, " +
        "multi-modal E2E-AutoML system, that can go directly from raw data to ML pipeline with minimal human supervision. " +
        "We show DSBox is able to outperform human experts and beat state-of-the-art AutoML systems " +
        "(ML-Plan, Auto-sklearn, TPOT) through evaluation on 468 diverse datasets with multiple media and task types.";


    static page_keywords = ["AutoML", "Data Cleaning", "Data Featurizing", "Human-in-the_Loop",
        "End-to-End Automated Machine Learning", 'Multi-Modal', "Optimization"]

    constructor(props) {
        super(props);
        this.research_title = DSBox.page_title;
        this.research_highlights = [
            "<a href=\"https://github.com/usc-isi-i2/dsbox-ta2\">DSBox</a>: End-to-End Automated Machine Learning (E2E-AutoML) system",
            "Supports high dimensional structured datasets (image, text, video, etc.)",
            "Research on a Monte-Carlo Tree Search(MCTS) Based Scheduling algorithm",
        ];
        this.research_details = [
            "End-to-End Automated Machine Learning (E2E-AutoML) seeks to automate the machine learning pipeline " +
            "generation process by augmenting, cleaning, and featurizing arbitrary data, as well as parametrizing " +
            "and tuning machine learning algorithms for optimal performance. Limitations in the media/task types " +
            "these systems support, lack of support for an open set of machine learning (ML) primitives, and " +
            "the black-box nature of the produced ML pipelines have made it difficult for such systems to be fully " +
            "exploited by users across many expertise levels. In this work we present DSBox, an expandable, " +
            "all-in-one E2E-AutoML system, that can pave the way to human-centric AI by meeting the needs of Non-ML " +
            "experts, while also allowing seasoned Data Scientists to easily incorporate their knowledge through " +
            "highly customizable templates that configure the pipeline search-space. We show DSBox is able to " +
            "outperform human experts and beat state-of-the-art AutoML systems (ML-Plan, Auto-sklearn, TPOT) " +
            "through evaluation on 468 diverse datasets."
        ]
        this.images = [
            dsbox_poster_img,
            dsbox_1_img, dsbox_2_img, dsbox_3_img, dsbox_4_img, dsbox_5_img, dsbox_6_img, dsbox_7_img,
            dsbox_tab_1_img, dsbox_tab_3_img, dsbox_tab_4_img
        ]
        this.citations = [
            'Qasemi E, Stan S, Yao K, Shao R, Liu J, Liang M, Ferrer LJ, Szekely P, "DSBox: Data Scientist in a Box", ISI Graduate Student Symposium (GSS \'19), Marina Del Rey, California, USA.'
        ]
    }

}

export default DSBox;