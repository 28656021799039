import React, {Component} from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

class MyProgBar extends Component {
    title;
    number;
    // data_color_groups;

    random_color() {
        const rnn = Math.random()
        // console.log(Math.floor(rnn*this.data_color_groups.length))
        return this.data_color_groups[Math.floor(rnn*this.data_color_groups.length)];
    }

    render() {

        const colors = this.random_color()


        return (
            <div className="pb_item">
                <div className={"flex-container"} style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: "space-around",
                    // alignItems: 'center',
                    // justifyContent: 'center',
                }}>
                    <span style={{flex: 10,}}>{this.props.title}</span>
                    <span style={{flex: 1,}}>{this.props.number}</span>
                </div>
                <style type={'text/css'}>
                    {`
                        .progress-bar {
                            background-image:  linear-gradient(to right, ${colors[0]}, ${colors[1]} );             
                        }
                    `}
                </style>
                <ProgressBar now={this.props.number}
                             // label={this.props.number+'%'}
                             style={{
                                'height': '14pt',
                                'backgroundColor': '#ffffff',
                            }}
                />
            </div>
        )
    }

    // constructor(props) {
    //     super(props);
    // }
    color_start_list = [
        "#79ccff",
        "#ff4646",
        "#0054ff",
        "#79ff7c",
        "#79ff8f",
        "#ff2d72",
        "#ff4646",
        "#79ccff",
        "#0054ff",
        "#79ff8f",
        "#ff2d72",
        "#79ff7c",
        "#79ccff",
        "#ff4646",
        "#0054ff",
        "#79ff7c",
        "#79ff8f",
        "#ff2d72",
        "#79ccff",
        "#ff4646",
        "#79ff7c",
        "#79ff8f",
        "#ff2d72",
        "#79ff7c",
        "#79ff8f",
        "#ff2d72",
        "#79ff7c",
        "#79ff8f",
        "#ff2d72",
        "#79ff7c",
        "#79ff8f",
        "#ff2d72",
        "#79ccff",
        "#ff4646",
        "#0054ff",
        "#79ff7c",
        "#79ff8f",
        "#ff2d72"];
    color_end_list = [
        "#9b74ff",
        "#ff26d9",
        "#ff23d3",
        "#ffbd4a",
        "#7b74ff",
        "#ffab79",
        "#ff26d9",
        "#9b74ff",
        "#ff23d3",
        "#7b74ff",
        "#ffab79",
        "#ffbd4a",
        "#9b74ff",
        "#ff26d9",
        "#ff23d3",
        "#ffbd4a",
        "#7b74ff",
        "#ffab79",
        "#9b74ff",
        "#ff26d9",
        "#ffbd4a",
        "#7b74ff",
        "#ffab79",
        "#ffbd4a",
        "#7b74ff",
        "#ffab79",
        "#ffbd4a",
        "#7b74ff",
        "#ffab79",
        "#ffbd4a",
        "#7b74ff",
        "#ffab79",
        "#9b74ff",
        "#ff26d9",
        "#ff23d3",
        "#ffbd4a",
        "#7b74ff",
        "#ffab79"];
    data_color_groups =  this.color_start_list.map((e, i) => {
        return [e, this.color_end_list[i]];
    });
    // console.log(this.data_color_groups)

}

export default MyProgBar