import React, { Component } from 'react';
import {Helmet} from "react-helmet";

import '../styles/bootstrap-4.1.2/bootstrap.min.css'
import '../styles/main_styles.css';
import '../styles/responsive.css';

import HeaderTabs from "../components/HeaderTabs";
import GeneralInfo from "../components/GeneralInfo";
import MyTabsList from "./TabsList";


class BaseTab extends Component {
    static mypath = 'BaseTab'

    static subpages = []
    constructor(props) {
        super(props);
        this._myname = BaseTab.mypath
    }
    render() {
        let page_name = this._myname
        return (
            <div className={'super_container'}>
                <Helmet>
                    <title>Ehsan Qasemi</title>
                    <meta charSet="utf-8"/>
                </Helmet>
                <HeaderTabs all_tabs={MyTabsList} page_name={page_name}/>
                <div className="content_container">
                    <div className="main_content_outer d-flex flex-xl-row flex-column
                                    align-items-start justify-content-start">
                        <GeneralInfo/>
                        {this.getContent()}
                    </div>
                </div>
            </div>
        )
    }

    getContent() {
        // return (<div></div>)
    }

}

export default BaseTab;