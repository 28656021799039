import React, {Component} from 'react';
import Col from "react-bootstrap/cjs/Col";

import USC from "../Images/Logos/USC.png"
import UW from "../Images/Logos/UW.png"
import UT from "../Images/Logos/UT.png"


import '../styles/bootstrap-4.1.2/bootstrap.min.css'
import '../styles/main_styles.css';
import '../styles/responsive.css';
import '../styles/education.css';
import BaseTab from "./BaseTab";

class Teaching extends BaseTab {

    static subpages = []
    static mypath='Teaching'

    getContent() {
        return (
            <div className="main_content">

                <div className="main_title_container d-flex flex-column align-items-start justify-content-end">
                    <div className="main_title">My Teachings</div>
                </div>
                <div className="main_content_scroll mCustomScrollbar" data-mcs-theme="minimal-dark">

                    <div className="education">
                        {this.getTeachingItem(
                            "2022",
                            USC,
                            "University of Southern California",
                            "",
                            [
                                "CSCI 544: Applied Natural Language Processing (M. Chen, R. Artstein) Spring 2022",
                                "CSCI 544: Applied Natural Language Processing (M. Rostami, R. Artstein) Fall 2022",
                            ]
                        )}

                        {this.getTeachingItem(
                            "2016-18",
                            UW,
                            "University of Wisconsin-Madison",
                            "",
                            [
                                "CS 354: Machine Organization and Programming (J. Skrentny) Spring 2018",
                                "CS 552: Introduction to Computer Architecture (Prof. Yu H. Hu) Fall 2016",
                                "CS 352: Digital System Fundamentals (K. Morrow, X. Zhang) Spring 2016",
                                "CS 252: Introduction to Computer Engineering (K. Morrow, M. Morrow) Spring 2016",
                                "ECE 376: Electrical and Electronic Circuits () Summer 2017",
                                "LCA 601,563: Advanced Persian Language (E. Barnard) Fall 2016",
                                "LCA 602,564: Advanced Persian Language (E. Barnard) Spring 2017",
                            ]
                        )}

                        {this.getTeachingItem(
                            "2010-15",
                            UT,
                            "University of Tehran",
                            "",
                            [
                                "ECE 615: Electronic System Level Design (B. Alizadeh) Spring 2015",
                                "ICEEP: Embedded Linux Workshop (Z. Navabi) Summer 2014",
                                "ECE 367: Digital Logic Design lab (Z. Navabi) Summer 2014",
                                "ECE 367: Digital Logic Design lab (Z. Navabi) Spring 2014",
                                "ECE 367: Digital Logic Design lab (Z. Navabi) Fall 2013",
                                "ECE 367: Digital Logic Design lab (Z. Navabi) Spring 2013",
                                "ECE 532: Object-Oriented Simulation of Electronic Systems (Z. Navabi) Spring 2014",
                                "ECE 532: Object-Oriented Simulation of Electronic Systems (Z. Navabi) Spring 2013",
                                "ECE 642: FPGA Base Embedded System Design (B. Alizadeh) Fall 2014",
                                "ECE 642: FPGA Base Embedded System Design (B. Alizadeh) Fall 2013",
                            ]
                        )}

                    </div>
                </div>
            </div>
        );
    }


    getTeachingItem(year, image, title, subtitle, courses ) {
        return (
            <div className="edu_item d-flex flex-lg-row flex-column align-items-start justfy-content-start">
                <Col className={"flex-column"} xs={2} md={2} lg={2} xl={2}>
                    <div className="edu_year">{year}</div>
                </Col>
                <Col xs={0.5}/>
                <Col className={"flex-column"} xs={2} md={2} lg={2} xl={2}>
                    <div className="edu_image">
                        <img src={image} style={{objectFit: "cover"}} alt=""/>
                    </div>
                </Col>
                <Col xs={0.5}/>
                <Col className="edu_content" xs={8} md={8} lg={8} xl={8}>
                    <div className="edu_title_container">
                        <div className="edu_title">{title}</div>
                        <div className="edu_subtitle">{subtitle}</div>
                    </div>
                    <div className="edu_text">
                        <p>
                            <ol>
                                {courses.map((c) => <li>{c}</li>)}
                            </ol>
                        </p>
                    </div>
                </Col>
            </div>
        );
    }

}

export default Teaching;
