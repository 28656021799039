import About from "./About";
import Education from "./Education";
import Research from "./Research";
import Experience from "./Experience";
import Skills from "./Skills";
import Teaching from "./Teaching";

const MyTabsList = [
    About,
    Education,
    Research,
    Experience,
    Skills,
    Teaching,
]

export default MyTabsList