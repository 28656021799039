import BasePortfolioEntry from "./BasePortfolioEntry";

import paco_logo from "../../Images/PaCo/MCQ_Overview_2-trimmy.png";
import paco_overview from "../../Images/PaCo/data_creation_overview.png";



class PaCo extends BasePortfolioEntry {
    static mypath='paco'
    static page_title = "PaCo: Preconditions Attributed to Commonsense Knowledge";
    static page_image = paco_logo;
    static page_briefs = "Propose suite of tasks to evaluate preconditioned inference of commonsense knowledge in SOTA models";

    static page_keywords = ["Knowledge Graph", "CommonSense", "NLP", "Semantic Role Labeling", 'ConceptNet', 'Amazon Mechanical Turk']

    constructor(props) {
        super(props);
        this.research_title = PaCo.page_title;
        this.research_highlights = [
            "Propose suite of tasks to evaluate preconditioned inference of commonsense knowledge in SOTA models",
        ];
        this.research_details = [
            "Humans can seamlessly reason with circumstantial preconditions of commonsense knowledge. We understand that a glass is used for drinking water, unless the glass is broken or the water is toxic. Despite state-of-the-art (SOTA) language models’ (LMs) impressive performance on inferring commonsense knowledge, it is unclear whether they understand the circumstantial preconditions. To address this gap, we propose a novel challenge of reasoning with circumstantial preconditions. We collect a dataset, called PaCo, consisting of 12.4 thousand preconditions of commonsense statements expressed in natural language. Based on this dataset, we create three canonical evaluation tasks and use them to examine the capability of existing LMs to understand situational preconditions. Our results reveal a 10-30% gap between machine and human performance on our tasks, which shows that reasoning with preconditions is an open challenge. Upon acceptance, we will release the dataset and the code used to test models.",
        ]
        this.images = [
            paco_logo, paco_overview
        ]
        this.citations = [
            "[In preparation for NAACL2022] Qasemi E, Ilievski F, Chen M, Szekely P. PaCo: Preconditions Attributed to Commonsense Knowledge. ",
        ]
    }

}

export default PaCo;