import BasePortfolioEntry from "./BasePortfolioEntry";

import heatwave_logo from "../../Images/AutoUnload/heatwave_logo.png";
import heatwave_unload from "../../Images/AutoUnload/unload.jpg";
import heatwave_advisor from "../../Images/AutoUnload/advisor.jpg";
import {Link} from "react-router-dom";



class AutoUnload extends BasePortfolioEntry {
    static mypath='autounload'
    static page_title = "AutoUnload: Optimizing Oracle Heatwave for Efficient Data Management";
    static page_image = heatwave_logo;
    static page_briefs = "Introduced and implemented AutoUnload and AutoUnload Advisor for Oracle Heatwave";

    static page_keywords = ["NLP", "Recommender", "Database"]

    constructor(props) {
        super(props);
        this.research_title = AutoUnload.page_title;
        this.research_highlights = [
            "Introduced and implemented AutoUnload feature in Oracle Heatwave, reducing memory footprint by up to 5x.",
            "Developed Unload Advisor (recommender) features in Oracle Heatwave, enhancing cluster performance by up to 10x"
        ];
        this.research_details = [
            "As the world of data continues to evolve, optimizing the performance and memory efficiency of database systems becomes paramount." +
            "During my internship at Oracle, I had the privilege to contribute to such an endeavor by developing the AutoUnload feature in Oracle Heatwave. " +
            "This groundbreaking feature not only streamlined memory utilization but also enhanced overall cluster performance." +
            "This innovative functionality was designed to address the challenge of managing tables that are loaded in heatwave but " +
            "are seldom or never queried with queries that benefit from Heatwave, which can consume valuable memory resources and impact the efficiency of the system.",
            "A key component of the AutoUnload feature is the Unload Advisor, an intelligent recommender that identifies tables within Heatwave's ecosystem that are infrequently used. The Unload Advisor goes beyond mere identification by predicting the amount of memory that can be saved by unloading these tables. This predictive capability helps administrators make informed decisions regarding data unloading, ensuring that only the most relevant data is retained in memory.",
            "To facilitate the unloading process, Oracle introduced the Auto Unload mechanism in MySQL 8.0.33. This feature automates several steps required for data unloading, simplifying the process and improving operational efficiency. Auto Unload can be executed using the heatwave_unload stored procedure, residing in the MySQL sys schema. This procedure streamlines the unloading process by excluding tables that cannot be unloaded, removing secondary engine flags, and effectively unloading data from Heatwave."
        ]
        this.images = [
            heatwave_logo, heatwave_unload, heatwave_advisor
        ]
        this.citations = [
            <Link to={{ pathname:'https://blogs.oracle.com/mysql/post/introducing-new-mysql-heatwave-features-for-lower-cost-and-better-performance'}}>
                Introducing new MySQL HeatWave features for lower cost and better performance
            </Link>,
            <Link to={{ pathname:'https://dev.mysql.com/doc/heatwave/en/mys-hw-auto-unload.html'}}>
                Unloading Data Using Auto Unload
            </Link>
        ]
    }

}

export default AutoUnload;