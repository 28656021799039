import BasePortfolioEntry from "./BasePortfolioEntry";

import cskg_logo from "../../Images/CSKG/cskg_logo.png";
import cq_logo from "../../Images/CQ/cq_overview_mini.png";



class CoreQuisite extends BasePortfolioEntry {
    static mypath='cq'
    static page_title = "CoreQuisite: Contextual Preconditions of Commonsense Knowledge.";
    static page_image = cq_logo;
    static page_briefs = "Evaluate reasoning with preconditions in SOTA models by proposing a multiple-choice question answering dataset";

    static page_keywords = ["Knowledge Graph", "CommonSense", "NLP", "Semantic Role Labeling", 'ConceptNet', 'Amazon Mechanical Turk']

    constructor(props) {
        super(props);
        this.research_title = CoreQuisite.page_title;
        this.research_highlights = [
            "Evaluate reasoning with preconditions in SOTA models by proposing a multiple-choice question answering dataset",
        ];
        this.research_details = [
            "Humans can seamlessly reason with preconditions of commonsense knowledge such as a glass is used for drinking water, unless the glass is broken. Despite language models’ (LMs) impressive performance on inferring commonsense knowledge, it is unclear whether they as capable. We collect a dataset, called CoreQuisite, consisting of 12.4 thousand preconditions of commonsense statements expressed in natural language. Based on this dataset, we create a multiple-choice question answering (P-MCQA) evaluation task and use it to examine the capability of existing LMs to understand situational preconditions. Our results reveal a 30% gap between machine and human performance on our task, which shows the task is an open challenge.",
        ]
        this.images = [
            cq_logo
        ]
        this.citations = [
            "Qasemi E, Ilievski F, Chen M, Szekely P. CoreQuisite: Contextual Preconditions of Commonsense Knowledge. WeCNLP 2021",
        ]
    }

}

export default CoreQuisite;