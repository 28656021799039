import BasePortfolioEntry from "./BasePortfolioEntry";

import cskg_logo from "../../Images/CSKG/cskg_logo.png";
import cskg_1_img from "../../Images/CSKG/cskg_1.png";
import cskg_sz_img from "../../Images/CSKG/cskg_size.png";
import cskg_sr_img from "../../Images/CSKG/cskg_sources.png";



class CSKG extends BasePortfolioEntry {
    static mypath='cskg'
    static page_title = "CSKG: Consolidating Commonsense Knowledge";
    static page_image = cskg_logo;
    static page_briefs = "In this work, we investigate and consolidate various resources of commonsense knowledge into a first integrated commonsense knowledge graph.";

    static page_keywords = ["Knowledge Graph", "CommonSense", "NLP", "FrameNet", 'ConceptNet']

    constructor(props) {
        super(props);
        this.research_title = CSKG.page_title;
        this.research_highlights = [
            "Consolidate seven separate sources of commonsense knowledge into a first integrated CommonSense Knowledge Graph (CSKG)",
        ];
        this.research_details = [
            "Commonsense reasoning is an important aspect of building robust AI systems and is" +
            "receiving significant attention in the natural language understanding, computer vision, and" +
            "knowledge graphs communities. At present, a number of valuable commonsense knowledge sources exist, " +
            "with different foci, strengths, and weaknesses. In this paper, we list" +
            "representative sources and their properties. Based on this survey, we propose principles" +
            "and a representation model in order to consolidate them into a CommonSense Knowledge" +
            "Graph (CSKG). We apply this approach to consolidate seven separate sources into a first" +
            "integrated CSKG. We present statistics of CSKG, present initial investigations of its utility" +
            "on four QA datasets, and list learned lessons",
        ]
        this.images = [
            cskg_1_img, cskg_sr_img, cskg_sz_img
        ]
        this.citations = [
            "Ilievski F, Szekely P, Cheng J, Zhang F, Qasemi E. Consolidating Commonsense Knowledge. arXiv preprint arXiv:2006.06114. 2020 Jun 10.",
        ]
    }

}

export default CSKG;