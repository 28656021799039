import BasePortfolioEntry from "./BasePortfolioEntry";

import music_img from "../../Images/Music/Music.png"
import G_img from "../../Images/Music/G_img.png";


class DeepArt extends BasePortfolioEntry {
    static mypath='deep_music'
    static page_title = "Deep Leaning and Empirical Topology in Music Style Detection";
    static page_image = G_img;
    static page_briefs = "Investigate deep learning applications as the computational intelligence medium for classification of music pieces according to style and historical period, such as Baroque, Classical and Romantic periods.";

    static page_keywords = ["Deep Learning", "Data Science", "Spaciotemporal", "Music History"]

    constructor(props) {
        super(props);
        this.research_title = DeepArt.page_title;
        this.research_highlights = [
            "propose deep learning as the computational intelligence medium for classification of music pieces according to style and historical period, such as Baroque, Classical and Romantic periods. ",
            "Big data local-to-global methods in analysis and prediction of dynamics using multiple modalities",
        ];
        this.research_details = [
            "We apply computational-mathematical models to\n" +
            "elucidate the brain’s pattern recognition from music in tasks such\n" +
            "as classification of music pieces according to style and historical\n" +
            "period, such as Baroque, Classical and Romantic periods. We\n" +
            "propose deep learning as the computational intelligence medium\n" +
            "of choice to implement the model. The feasibility study reported\n" +
            "below explores a special case to design an algorithm that could\n" +
            "distinguish different styles of music, especially the Baroque music\n" +
            "and the romantic music. The input is a piece of music, and the\n" +
            "output will be the style of the music.",
        ]
        this.images = [
            music_img
        ]
        this.citations = [
            "Han P, Qasemi E, Ardalan A, Gao H, Assadi AH. Deep Learning Empirical Topology for Classical Music Style Decision Making. In2017 International Conference on Computational Science and Computational Intelligence (CSCI) 2017 Dec 14 (pp. 394-395). IEEE.",
        ]
    }

}

export default DeepArt;