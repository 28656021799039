import BasePortfolioEntry from "./BasePortfolioEntry";

import air_img from "../../Images/Air/air.jpeg";
import air_res_img from "../../Images/Air/air_res.png";


class DeepAir extends BasePortfolioEntry {
    static mypath='deep_air'
    static page_title = "Deep Learning Features in Atmospheric Chemistry";
    static page_image = air_img;
    static page_briefs = "Big data local-to-global methods in analysis and prediction of dynamics in atmospheric chemistry spatiotemporal data.";

    static page_keywords = ["Deep Learning", "Data Science", "Atmospheric Chemistry", "spaciotemporal"]

    constructor(props) {
        super(props);
        this.research_title = DeepAir.page_title;
        this.research_highlights = [
            "Big data local-to-global methods in analysis and prediction of dynamics in atmospheric chemistry spatiotemporal data.",
        ];
        this.research_details = [
            "Atmospheric Chemistry is important in public\n" +
            "health. This paper highlights methodology aspects from analysis\n" +
            "of atmospheric chemistry data in China, its correlation with\n" +
            "cancer morbidity data, and impact on future cancer morbidity rate\n" +
            "that are due to changes in climate.Forthcoming papers use DL for\n" +
            "prediction. Research on cancer and analysis of cancer morbidity\n" +
            "are provided by Liu Yuxin, Zhaorong Zhu.",
        ]
        this.images = [
            air_res_img
        ]
        this.citations = [
            "Yu F, Thayer M, Qasemi E, Zhu K, Assadi A. Deep Learning Features in Atmospheric Chemistry: Prediction of Cancer Morbidity Due to Air Pollution. In2017 International Conference on Computational Science and Computational Intelligence (CSCI) 2017 Dec 14 (pp. 1764-1766). IEEE.",
        ]
    }

}

export default DeepAir;