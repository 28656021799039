import React from 'react';
import Col from "react-bootstrap/cjs/Col";

import USC from "../Images/Logos/USC.png"
import ISI from "../Images/Logos/isi.png"
import UW from "../Images/Logos/UW.png"
import UT from "../Images/Logos/UT.png"
import Bosch from "../Images/Logos/Bosch.png"
import Oracle from "../Images/Logos/Oracle.png"

import ASD from "./Portfolio/ASD"
import DeepMusic from "./Portfolio/DeepMusic";
import DeepArt from "./Portfolio/DeepArt"
import DeepAir from "./Portfolio/DeepAir"
import Blokus from "./Portfolio/Blokus";
import TableUnderstanding from './Portfolio/TableUnderstanding'
import AES from "./Portfolio/AES";
import HLS from "./Portfolio/HLS";
import DSBox from "./Portfolio/DSBox"
import SubMem from "./Portfolio/SubMem"
import CSKG from "./Portfolio/CSKG"
import HSM from "./Portfolio/HSM"
import Pardis from "./Portfolio/Pardis"

import PInKS from "./Portfolio/PInKS";
import PRISM from "./Portfolio/PRISM";
import CQ from "./Portfolio/CQ";
import PaCo from "./Portfolio/PaCo";

import TKG from "./Portfolio/TrafficKG";
import TRIVIA from "./Portfolio/TRIVIA";
import VIPHY from "./Portfolio/VIPHY";
import AutoUnload from "./Portfolio/AutoUnload";


import '../styles/bootstrap-4.1.2/bootstrap.min.css';
import '../styles/main_styles.css';
import '../styles/responsive.css';
import '../styles/education.css';
import BaseTab from "./BaseTab";
import {Link} from "react-router-dom";



class Experience extends BaseTab {

    static subpages = []
    static mypath='Experience'

    constructor(props) {
        super(props);

        // let research_dict = Object.assign({}, ...Research.subpages.map(res => ({[res[1]]: res[0]})));

        let experience_json = [
            {
                year:"2018-2023",
                img:USC,
                title:"Graduate Research Assistant (GRA)",
                place:<span><a href="https://usc-isi-i2.github.io/home/">Center on Knowledge Graphs</a> (USC, Los Angeles, Ca, US)</span>,
                achievement: [
                    PRISM,
                    PInKS,
                    CQ,
                    PaCo,
                    CSKG,
                    DSBox,
                    TableUnderstanding,
                    VIPHY
                ]
            },
            {
                year:"Fall 2022 - Winter 2023",
                img:Oracle,
                title:"Research Intern @ Heatwave Team",
                place:<span><a href="https://www.oracle.com/mysql/heatwave/">Oracle</a> (Los Angeles, CA, US)</span>,
                achievement: [
                    AutoUnload
                ]
            },
            {
                year:"Summer 2022",
                img:Bosch,
                title:"Hybrid AI Research Intern",
                place:<span><a href="https://www.bosch.us/our-company/bosch-in-the-usa/pittsburgh/">Bosch LLC</a> (Pittsburgh, PA, US)</span>,
                achievement: [
                    TRIVIA,
                ]
            },
            {
                year:"Summer 2021",
                img:Bosch,
                title:"Hybrid AI Research Intern",
                place:<span><a href="https://www.bosch.us/our-company/bosch-in-the-usa/pittsburgh/">Bosch LLC</a> (Pittsburgh, PA, US)</span>,
                achievement: [
                    TKG,
                ]
            },
            {
                year:"Summer 2018",
                img:ISI,
                title:"Intern",
                place:<span><a href="https://www.isi.edu/">Information Sciences Institute</a> (ISI, Marina Del Rey, Ca, US)</span>,
                achievement: [
                    DSBox,
                ]
            },
            {
                year:"2016-18",
                img:UW,
                title:"Research Assistant",
                place:<span><a href="http://persepolis.wisc.edu/">Persepolis Group</a> (UW, Madison, Wi, US)</span>,
                achievement: [
                    ASD,
                    DeepArt,
                    DeepMusic,
                    DeepAir
                ]
            },
            {
                year:"2016-17",
                img:UW,
                title:"Instructor",
                place:<span><a href="https://alc.wisc.edu/">Asian Languages and Culture</a> (UW, Madison, Wi, US)</span>,
                achievement: [
                ]
            },
            {
                year:"Summer 2016",
                img:'',
                title:"Part-Time SW Engineer",
                place:<span><a href="#">Pardis Co.</a> (Tehran, Iran)</span>,
                achievement: [
                    Pardis
                ]
            },
            {
                year:"2014-15",
                img:'',
                title:"HW/SW Engineer",
                place:<span><a href="https://iwin.ir">IWIN Co.</a> (Tehran, Iran)</span>,
                achievement: [
                    HSM
                ]
            },
            {
                year:"2013-14",
                img:UT,
                title:"Research Assistant",
                place:<span><a href="https://dvdes.ut.ac.ir/">Design Verification and Debug Lab</a> (UT, Tehran, Iran)</span>,
                achievement: [
                    AES,
                    Blokus,
                    HLS,
                ]
            },
            {
                year:"Summer 2013",
                img:UT,
                title:"Intern",
                place:<span><a href="#">University of Tehran</a></span>,
                achievement: [
                    SubMem,
                ]
            },
            {
                year:"2011",
                img:'',
                title:"Part-Time SW Engineering",
                place:<span><a href="#">S. T. Farabi Co.</a></span>,
                achievement: []
            },
        ]

        this.experiences = experience_json.map((expr) => {
            let new_expr = expr
            new_expr.achievement = expr.achievement.map(a => {
                return {
                    link: `/research/${a.mypath}`,
                    title: a.page_title,
                }
            })
            return new_expr
        })
    }

    getContent() {
        return (
            <div className="main_content">

                <div className="main_title_container d-flex flex-column align-items-start justify-content-end">
                    <div className="main_title">My Experience</div>
                </div>
                <div className="main_content_scroll mCustomScrollbar" data-mcs-theme="minimal-dark">

                    <div className="education">
                        {this.experiences.map(this.getExperienceItem)}
                    </div>
                </div>
            </div>
        );
    }


    getExperienceItem(expr) {
        return (
            <div className="edu_item d-flex flex-lg-row flex-column align-items-start justfy-content-start">
                <Col className={"flex-column"} xs={2}>
                    <div className="edu_year">{expr.year}</div>
                </Col>
                <Col xs={0.5}/>
                <Col className={"flex-column"} xs md={2}>
                    <div className="edu_image">
                        <img src={expr.img} style={{objectFit: "cover"}} alt=""/>
                    </div>
                </Col>
                <Col xs={0.5}/>
                <Col className="edu_content" xs={9}>
                    <div className="edu_title_container">
                        <div className="edu_title">{expr.title}</div>
                        <div className="edu_subtitle">{expr.place}</div>
                    </div>
                    {(expr.achievement.length > 0 &&
                        <div className="edu_text">
                            <p>
                                <b>Achievements:</b>
                                <br/>
                            </p>
                            <ul>
                                {expr.achievement.map(a_t => {
                                    // return <li><a href={a_t.link}>{a_t.title}</a></li>
                                    return  <li key={a_t.title+expr.title+"_expr"}>
                                        <Link to={a_t.link.toLowerCase()}>{a_t.title}</Link>
                                    </li>
                                })}
                            </ul>

                        </div>)}
                </Col>
            </div>
        );
    }

}

export default Experience;
