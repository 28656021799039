import React, {Component} from "react";
// import MyPhoto from "../Images/me.jpg"
import MyPhoto from "../Images/me_formal.jpg"
import '../styles/responsive.css';
import '../styles/main_styles.css';
import "../styles/font-awesome-4.7.0/css/font-awesome.css"


class GeneralInfo extends Component {
    constructor(props) {
        super(props);
        this.contact = [
            {icon: "fa fa-envelope", ref: 'mailto:qasemi@usc.edu',text: "qasemi@usc.edu"},
            {icon: "fa fa-envelope", ref: 'mailto:qasemi.ehs@gmail.com',text: "qasemi.ehs@gmail.com"},
            {icon: "fa fa-home", ref: 'http://ehsanqasemi.com', text: "ehsanqasemi.com"},
            {icon: "fa fa-github", ref: 'https://github.com/proska', text: "proska"},
            // {icon: "fa fa-twitter", ref: 'https://twitter.com/Proska_', text: "Proska_"},
            {icon: "fa fa-linkedin", ref: 'https://www.linkedin.com/in/ehsan-qasemi-39627477/', text: "ehsan-qasemi"},
        ]
    }
    render() {
        return (
            <div className="general_info d-flex flex-xl-column flex-md-row flex-column"
                    id="general_information">

                {/*<div>*/}
                    <div className="general_info_image" >
                        <div className="background_image" style={{backgroundImage: "url(" + MyPhoto + ")"}}/>
                    </div>
                {/*</div>*/}
                <div className="general_info_content">
                    <div className="general_info_content_inner mCustomScrollbar" data-mcs-theme="minimal-dark">
                        <div className="general_info_title">General Information</div>
                        <ul className="general_info_list">
                            {
                                this.contact.map(cnt => (
                                    <li className="d-flex flex-row align-items-center justify-content-start" key={cnt.ref+cnt.text}>
                                        <div
                                            className="general_info_icon d-flex flex-column align-items-start justify-content-center">
                                            <i className={cnt.icon} style={{color: "#8583e1"}}/>
                                        </div>
                                        <div className="general_info_text">
                                            <a href={cnt.ref}>{cnt.text}</a>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default GeneralInfo;