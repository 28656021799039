import React from 'react';

import BaseTab from "./BaseTab";
import ASD from "./Portfolio/ASD"
import DeepMusic from "./Portfolio/DeepMusic";
import DeepArt from "./Portfolio/DeepArt"
import DeepAir from "./Portfolio/DeepAir"
import Blokus from "./Portfolio/Blokus";
import TableUnderstanding from './Portfolio/TableUnderstanding'
import AES from "./Portfolio/AES";
import HLS from "./Portfolio/HLS";
import DSBox from "./Portfolio/DSBox"
import ADepthLR from "./Portfolio/ADepthLR"
import SubMem from "./Portfolio/SubMem"
import CSKG from "./Portfolio/CSKG"
import HSM from "./Portfolio/HSM"
import Pardis from "./Portfolio/Pardis"

import PInKS from "./Portfolio/PInKS";
import CQ from "./Portfolio/CQ";
import PaCo from "./Portfolio/PaCo";
import TKG from "./Portfolio/TrafficKG";
import PRISM from "./Portfolio/PRISM";
import TRIVIA from "./Portfolio/TRIVIA";
import VIPHY from "./Portfolio/VIPHY";
import AutoUnload from "./Portfolio/AutoUnload";


import '../styles/bootstrap-4.1.2/bootstrap.min.css'
import '../styles/main_styles.css';
import '../styles/responsive.css';
import '../styles/portfolio.css';
import {Link} from "react-router-dom";
import {Row, Container} from "react-bootstrap";



// TODO: reduce width of items and make the Images smaller
//  Make sure the screen looks nice in smaller window sizes

class Research extends BaseTab {

    static subpages = [
        PRISM,
        TRIVIA,
        PInKS,
        PaCo,
        AutoUnload,
        VIPHY,
        TKG,
        CSKG,
        CQ,
        DSBox,
        TableUnderstanding,
        ASD,
        ADepthLR,
        DeepArt,
        DeepMusic,
        DeepAir,
        HSM,
        Blokus,
        AES,
        Pardis,
        HLS,
        SubMem,
    ]
    static mypath='Research'

    constructor(props) {
        super(props);

        this.myResearch = Research.subpages.map((subpage) => {
            return {
                keywords: subpage.page_keywords,
                image: subpage.page_image,
                title: subpage.page_title,
                brief: subpage.page_briefs,
                path: subpage.mypath,
                visible: true,
            }
        })

        this.state = {
            visibility: this.myResearch,
            checked: '*',
        }

        this.categories = [
            ["*", "All"],
            ["nlp", "NLP"],
            // ["spaciotemporal", "SpacioTemporal"],
            // ["graph", "Graph-ML"],
            ["vision", "CV"],
            ["system automl spaciotemporal ai ml", "ML"],
            // ["combinatorial", "Optimization"],
            ["security combinatorial", "Other"],
        ]

        // this.cat_counts = {}
        // this.categories.map((cat) => {
        //     this.cat_counts[cat[1]] = (this.myResearch.map((res) => {
        //             let clean_keys = res.keywords.toString().replaceAll(",", " ").toLowerCase()
        //             if (cat[0] === '*') {
        //                 return 1
        //             } else {
        //                 return this.is_query_match(cat[0], clean_keys)
        //             }
        //         })).reduce((a, b) => a + b, 0)
        //     }
        // )
        // alert(this.cat_counts['ML'])
    }

    is_query_match(query, keywords) {
        let q_set = query.split(' ')
        let l_set = keywords.split(' ')
        // console.log(`${q_set} <> ${l_set} == ${out}`)

        return q_set.some(q => l_set.some(l => l === q))
    }

    change_query_wrapper(query) {

        return () => {
            this.setState({
                checked: query,
                visibility: this.myResearch.map((res) => {
                    let d = res;
                    let clean_keys = res.keywords.toString().replaceAll(","," ").toLowerCase()
                    if(query === '*')
                        d.visible = true
                    else
                        d.visible = this.is_query_match(query, clean_keys)
                    return d
                })
            })
        }
    }

    getContent() {

        return (
            <div className="main_content">
                <div className="main_title_container d-flex flex-column align-items-start justify-content-end">
                    <div className="main_title">My Research</div>
                </div>


                {this.generate_categories_list()}
                <div className="main_content_scroll mCustomScrollbar container-fluid" data-mcs-theme="minimal-dark">
                    <div className="portfolio_grid grid clearfix row">
                        {
                            this.state.visibility.map(this.getResearchItem)
                        }
                    </div>
                </div>
            </div>
        );
    }

    generate_categories_list() {
        return <div className="portfolio_categories button-group filters-button-group">
            <ul>
                {
                    this.categories.map((cat_name) => {

                        let checked = ""
                        if (cat_name[0].includes(this.state.checked))
                            checked = "is-checked"
                        // let count = this.cat_counts[cat_name[1]]
                        return <li className={"portfolio_category active" + checked}
                                   onClick={this.change_query_wrapper(cat_name[0])}
                                   key={cat_name[1]}>{cat_name[1]}</li>
                    })
                }
            </ul>
        </div>;
    }

    getResearchItem(res) {
        // console.log(title)
        return (
            (
                res.visible &&
                    <div className={'grind-item-bundle col-12'} key={res.title}>
                        <h3 className={'portfolio_item_title'}>{res.title}</h3>
                        {/*<Row>*/}
                        {/*    <Col className={"portfolio_item"} xs={4} key={res.title}>*/}
                        {/*        <img src={res.image} alt=""/>*/}

                        {/*        <Link to={`${Research.mypath.toLowerCase()}/${res.path}`}>*/}
                        {/*            <div className={"portfolio_item_content d-flex flex-column align-items-center justify-content-center"}/>*/}
                        {/*        </Link>*/}
                        {/*    </Col>*/}
                        {/*    <Col className={"portfolio-item-description"} xs={8}>*/}
                        {/*        <p>*/}
                        {/*            <strong>Brief: </strong>{res.brief}*/}
                        {/*        </p>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <Container>
                            <Row className={"portfolio_item"} key={res.title} >
                            {/*<Row key={res.title}>*/}
                                <img src={res.image} alt=""/>

                                <Link to={`${Research.mypath.toLowerCase()}/${res.path}`}>
                                    <div className={"portfolio_item_content d-flex flex-column align-items-center " +
                                        "justify-content-center"}/>
                                </Link>

                            </Row>
                            <Row className={"portfolio-item-description"}>
                            {/*<Row >*/}
                            {/*    <p>*/}
                                    <strong>Brief: </strong>{res.brief}
                                {/*</p>*/}
                            </Row>
                        </Container>
                    </div>
            )
        );
    }
}


export default Research;
