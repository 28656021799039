import BasePortfolioEntry from "./BasePortfolioEntry";

import mem_img from "../../Images/memory.jpg"


class SubMem extends BasePortfolioEntry {
    static mypath = 'submem'
    static page_title = "Subthreshold Memory Compiler";
    static page_image = mem_img;
    static page_briefs = "In this work, we investigated SRAM memory architectures to work in subthreshold voltage range for low-power applications. We have proposed a set of cell architectures that improve read, write, and hold stability compared to state-of-the-art cell architectures. Finally, we developed our in-house memory compiler tool that assembles such memory cells into full SRAM memory with arbitrary size.";

    static page_keywords = ["SRAM", "Volatile Memory", "Cache", "Subthreshold", "Low-Power", 'Memory Compiler', "TSMC 180 nm", "TSMC 90 nm", "TSMC 45 nm"]

    constructor(props) {
        super(props);
        this.research_title = SubMem.page_title;
        this.research_highlights = [
            "Design memory compiler tool for assembling SRAM memory cells into full size memories",
            "Proposed T-Gate based SRAM cell design that improves write-ability of conventional designs in subthreshold operating region",
            "Propose a 8-Transistor SRAM cell that improves Write Noise Margin (WNM) by 60% over conventional 6T cel",
        ];
        this.research_details = [
        ]
        this.images = [
            mem_img
        ]
        this.citations = [
            "Pasandi G, Qasemi E, Fakhraie SM. A new low-leakage T-Gate based 8T SRAM cell with improved write-ability in 90nm CMOS technology. In2014 22nd Iranian Conference on Electrical Engineering (ICEE) 2014 May 20 (pp. 382-386). IEEE.",
            "Pasandi G, Fakhraie SM, Qasemi E. A New Tri-State Based Static Random Access Memory (SRAM) with Improved Write-Ability and Read Stability. In2014 JCSE Vol. 10, No.2 & 4, Summer 2012 & Winter 2013"
        ]
    }

}

export default SubMem;