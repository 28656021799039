import BasePortfolioEntry from "./BasePortfolioEntry";

import tkg_logo from "../../Images/TKG/HANS-arch1.png";
import tkg_gui from "../../Images/TKG/HANSdemoGUI.png";
import tkg_ont from "../../Images/TKG/TMO.png";



class TKG extends BasePortfolioEntry {
    static mypath='traffickg'
    static page_title = "A Neuro-symbolic Architecture for Intelligent Traffic Monitoring";
    static page_image = tkg_logo;
    static page_briefs = "Human-Assisted Neuro-Symbolic Architecture for Multi-modal Intelligent Traffic Monitoring";

    static page_keywords = ["Traffic Monitoring", "Knowledge Graph", "CommonSense", "NLP", "ML", "Machine Learning"]

    constructor(props) {
        super(props);
        this.research_title = TKG.page_title;
        this.research_highlights = [
            "Propose an improved model for reasoning with preconditions through minimum supervision. ",
        ];
        this.research_details = [
            "Reasoning with preconditions such as “glass can be used for drinking water unless the glass is shattered” remains an open-problem for language models. The main challenge lies in the scarcity of preconditions data and model’s lack of support for such reasoning. We present PInKS , Preconditioned Commonsense Inference with WeaK Supervision, an improved model for reasoning with preconditions through minimum supervision. We show, both empirically and theoretically, that PInKS improves the results across the benchmarks on reasoning with the preconditions of commonsense knowledge (up to 0.4 Macro-F1 scores). We further investigate the robustness of our method through PAC-Bayesian informativeness analysis, recall measures and ablation study.",
        ]
        this.images = [
            tkg_logo, tkg_gui, tkg_ont
        ]
        this.citations = [
            "[In preparation for IJCAI2022] Qasemi E, Oltramari A. A Neuro-symbolic Architecture for Intelligent Traffic Monitoring",
        ]
    }

}

export default TKG;