import React from 'react';
import Col from "react-bootstrap/cjs/Col";

import USC from "../Images/Logos/USC.png"
import UW from "../Images/Logos/UW.png"
import UT from "../Images/Logos/UT.png"

import '../styles/bootstrap-4.1.2/bootstrap.min.css'
import '../styles/main_styles.css';
import '../styles/responsive.css';
import BaseTab from "./BaseTab";

class Education extends BaseTab {

    static mypath = 'Education'
    constructor(props) {
        super(props);
        this._myname = Education.mypath
    }
    getContent() {
        return (
            <div className="main_content">

                <div className="main_title_container d-flex flex-column align-items-start justify-content-end">
                    <div className="main_title">My Education</div>
                </div>
                <div className="main_content_scroll mCustomScrollbar" data-mcs-theme="minimal-dark">

                    <div className="education">

                        {this.getEducationItem(
                            "2023",
                            USC,
                            "University of Southern California",
                            "PhD. in Computer Science",
                            "Advanced Algorithms (CSCI 670), Natural Language Processing (CSCI 544)"
                        )}

                        {this.getEducationItem(
                            "2018",
                            UW,
                            "University of Wisconsin-Madison",
                            "Msc. in Computer Science",
                            "Machine Learning (CS760), Information Security(CS642),Theory & application of Pattern Recognition(CS532), HPC in Engineering(CS759), Optimization(CS524), Intro. Operating Systems(CS537), DataBase Managements Sys. (CS564), BigData (CS744), Data Visualization (CS765)."
                        )}

                        {this.getEducationItem(
                            "2015",
                            UT,
                            "University of Tehran",
                            "Bsc. in Electrical Engineering: Digital Systems",
                            "Digital Signal Processing, Computer Network, Data Structure & Algorithm, Parallel Programming, OO Modeling, Verification"
                        )}

                    </div>
                </div>
            </div>
        );
    }


    getEducationItem(year, image, title, subtitle, courses ) {
        return (
            <div className="edu_item d-flex flex-lg-row flex-column align-items-start justfy-content-start">
                <Col className={"flex-column"} xs={2}>
                    <div className="edu_year">{year}</div>
                </Col>
                <Col xs={0.5}/>
                <Col className={"flex-column"} xs={2}>
                    <div className="edu_image">
                        <img src={image} style={{objectFit: "cover"}} alt=""/>
                    </div>
                </Col>
                <Col xs={0.5}/>
                <Col className="edu_content" xs={9}>
                    <div className="edu_title_container">
                        <div className="edu_title">{title}</div>
                        <div className="edu_subtitle">{subtitle}</div>
                    </div>
                    <div className="edu_text">
                        <p>
                            <b>Notable Coursework:</b>
                            <br/>
                            {courses}
                        </p>
                    </div>
                </Col>
            </div>
        );
    }

}

export default Education;
