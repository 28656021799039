import BasePortfolioEntry from "./BasePortfolioEntry";

import hls_img from '../../Images/HLS/hls.jpg'
import hls_3_img from '../../Images/HLS/hls_3.png'
import hls_4_img from '../../Images/HLS/hls_4.png'
import hls_5_img from '../../Images/HLS/hls_5.png'
import hls_tab_img from '../../Images/HLS/hls_tab_2.png'

class HLS extends BasePortfolioEntry {
    static mypath = 'hls'

    static page_title = "Stochastic Methods and Monte-Carlo Tree Search (MCTS) Applications in High Level Synthesis";
    static page_image = hls_img;
    static page_briefs = "In this work, we address the problem of scheduling in high-level synthesis application. " +
        "We propose a new scheduling algorithm based on Monte-Carlo (MC) simulation that uses MC Tree Search (MCTS)" +
        "method as the heart of our scheme handles the scheduling" +
        "to produce optimized scheduling with both time and resource constraints.";

    static page_keywords = ["High-Level Synthesis", "Scheduling", "Stochastic Decision Making", "Random Simulation",
        "Monte Carlo Tree Search", "MCTS", "Decision Graph", "Optimization", "Combinatorial"]

    constructor(props) {
        super(props);
        this.research_title = HLS.page_title;
        this.research_highlights = [
            "Study on power-aware High level synthesis tools and high-performance low-cost machine-learning solutions",
            "Design and Implementation of a DSP-specific, low-power HLS tool",
            "Research on a Monte-Carlo Tree Search(MCTS) Based Scheduling algorithm",
        ];
        this.research_details = [
            "we address the problem of" +
            "scheduling during high-level synthesis (HLS). Instead of using" +
            "or improving existing heuristics like Force Directed List" +
            "Scheduling (FDLS), we propose a new scheduling algorithm" +
            "which is based on Monte-Carlo (MC) simulation. In the" +
            "proposed scheduling algorithm, MC Tree Search (MCTS)" +
            "method as the heart of our scheme handles the scheduling" +
            "problem with both timing and resource constraints. The results" +
            "show that our method is able to schedule large industrial test" +
            "cases up to two orders of magnitude faster than existing" +
            "methods such as FDLS with up to 15% better latency."
        ]
        this.images = [
            hls_img, hls_3_img, hls_4_img, hls_5_img, hls_tab_img
        ]
        this.citations = [
            "Qasemi E, Samadi A, Shadmehr MH, Azizian B, Mozaffari S, Shirian A, Alizadeh B. Highly scalable, shared-memory, Monte-Carlo tree search based Blokus Duo Solver on FPGA. In2014 International Conference on Field-Programmable Technology (FPT) 2014 Dec 10 (pp. 370-373). IEEE."
        ]
    }

}

export default HLS;