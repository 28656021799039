import {Link} from "react-router-dom";
import React from 'react';

import '../styles/bootstrap-4.1.2/bootstrap.min.css'
import '../styles/main_styles.css';
import '../styles/responsive.css';
import BaseTab from "./BaseTab";

import mycv from "../PDFs/Ehsan_Qasemi_CV.pdf"
import myresume from "../PDFs/Ehsan_Qasemi_resume.pdf"
import {Col, Row} from "react-bootstrap";


class About extends BaseTab {
    static mypath='About'
    constructor(props) {
        super(props);
        this._myname = About.mypath;
        this.my_updates = [
            ["7/2023", "Our paper \"Traffic-Domain Video Question Answering with Automatic Captioning\" has been accepted to ITSC 2023"],
            ["5/2023", "I passed my dissertation final defense on \"multi - modal preconditioned inference of commonsense knowledge\"."],
            // ["1/2023", "Submitted 3 papers on multi-modal commonsense inference to ACL2023"],
            // ["12/2022", "I defended my thesis proposal on \"multi-modal preconditioned inference of commonsense knowledge"],
            ["12/2022", "Our paper \"PaCo: Preconditions Attributed to Commonsense Knowledge\" has been accepted to EMNLP2022"],
            ["11/2022", "Our paper \"PInKS: Preconditioned Commonsense Inference with Minimal Supervision\" has been accepted to AACL2022"],
            ["10/2022", "Starting my internship at Oracle as researcher. I will be working on AutoUnload feature and its recommender system"],
            ["5/23/2022", "Starting my second internship at Bosch LLC as researcher on hybrid AI"],
            ["1/5/2022", "I will be TA for CSCI544 (\"Applied Natural Language Processing\") for Spring 2022 at USC-Viterbi under M. Chen and R. Artstein"],
            ["12/15/2021", "Submitted two papers on commonsense inference to ARR"],
            ["10/4/2021", "Our short paper \"CoreQuisite: Circumstantial Preconditions of Commonsense Knowledge\" was accepted in WeNLP2021"],
            ["5/24/2021", "Starting my internship at Bosch LLC as researcher on hybrid AI"],
        ];
    }
    getContent() {
        return (
            <div className="main_content">
                <div className="main_title_container d-flex flex-column align-items-start justify-content-end">
                    <div className="main_title">Ehsan Qasemi</div>
                </div>
                <div className="main_content_scroll mCustomScrollbar" data-mcs-theme="minimal-dark">
                    <div className="about_content">
                        <div className="about_title">My Short Bio:</div>
                        <div className="about_text">
                            <p>
                                My name is Ehsan Qasemi. I am currently pursuing my PhD. in computer science at
                                University of Southern California (USC) under supervision of
                                <a href={"https://muhaochen.github.io/"}> Muhao Chen</a> and
                                <a href={"https://usc-isi-i2.github.io/szekely/"}> Pedro Szekely</a>.
                                My current research is focused at the intersection of <strong>Natural Language Processing (NLP)</strong>,
                                <strong>Machine Learning (ML)</strong>, and <strong>Knowledge Graphs (KG)</strong> with focus on Machine Common Sense
                                (MCS) reasoning.
                                My work has been partially funded by DARPA's
                                <a href={"https://www.darpa.mil/program/machine-common-sense"}> Machine Common Sense (MCS)</a> and
                                <a href={"https://www.darpa.mil/program/data-driven-discovery-of-models"}> Data-Driven Discovery of Models (D3M)</a> programs.
                            </p>
                            {/*<p>*/}
                            {/*    I am honored to get my*/}
                            {/*    masters in computer science and Computer Engineering from university of*/}
                            {/*    Winsconsin-Madison; and my BSc. in Elecetrical Engineering: Digital Systems from*/}
                            {/*    University of Tehran (UT). During my professional life, I have contributed to projects*/}
                            {/*    in BigData, Bioinformatics, Deep Learning, Computer Security, high-performance*/}
                            {/*    computing, embedded systems, secure hardware, data analysis, and computer architecture.*/}
                            {/*</p>*/}
                            <p>
                                Feel free to check my GitHub and reach out if you want to talk about my projects.
                            </p>
                            <hr/>
                            <div className={"main_nav d-flex flex-row align-items-end justify-content-start"}>
                                <ul className={"d-flex flex-row align-items-center justify-content-start"}>
                                    <li key='resume'><Link to={myresume}>My Resume</Link></li>
                                    <li key='cv'><Link to={mycv}>My CV</Link></li>
                                    <li key='calendar'><Link to={'MyCalendar'}>My Calendar</Link></li>
                                </ul>
                            </div>
                            <br/>
                            <hr/>
                            <div className={"d-flex flex-row align-items-end justify-content-start"}>
                                <div className={"updates"}>
                                    <h2>Updates:</h2>
                                    <ul>
                                        {this.my_updates.map((entry) =>
                                            <li key={entry[0]+entry[1].slice(0,10)}>
                                                <Row>
                                                    <Col xs={2} md={2} lg={2} xl={2}>
                                                        {entry[0]}
                                                    </Col>
                                                    <Col xs={10} md={10} lg={10} xl={10}>
                                                        {entry[1]}
                                                    </Col>
                                                </Row>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    );
    }


}

export default About;
